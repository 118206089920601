:local(.cnt) {
	min-height: 100dvh;
	width: 100%;
	background: var(--mainColorBk);
	&:local(.ok) {
		background: var(--okColor);
	}
	&:local(.error) {
		background: var(--errorColor);
	}
}

:local(.inner) {
	margin: 0 auto;
	display: grid;
	height: 100dvh;
	place-items: center;
	max-width: 500px;
	position: relative;
}
:local(.instructions) {
	line-height: 200%;
	margin-bottom: var(--spacing2x);
}

:local(.start) {
	text-align: center;
}
:local(.logo) {
	width: 200px;
}
:local(.innerApp) {
	grid-template-rows: auto 1fr;
	justify-items: stretch;
	align-items: flex-start;
}

:local(.topbar) {
	height: 50px;
	font-family: 'Zen Dots', 'Noto Sans', -apple-system, BlinkMacSystemFont,
		'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	display: grid;
	grid-template-columns: auto 1fr auto;
	margin-bottom: 0px;
	align-items: center;
	gap: var(--spacing);
	padding: var(--spacing_half) var(--spacing);
	font-size: 16px;
	color: #444;
	:local(.lives) {
		img {
			width: 20px;
			margin-right: var(--spacing_half);
		}
	}
}

:local(.cardContainer) {
}

:local(.audioToggle) {
	padding: 10px;
	img {
		width: 20px;
	}
}

:local(.gameOverMessage) {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
	padding: var(--spacing4x);
	text-align: center;
	:local(.title) {
		font-size: 24px;
		margin-bottom: var(--spacing2x);
	}
	:local(.info) {
		font-size: 18px;
		margin-bottom: var(--spacing2x);
		line-height: 200%;
	}
}

:local(.button) {
	background-color: var(--mainColor);
	font-size: 18px;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	color: #fff;
	padding: var(--spacing);
	text-align: center;
}

:local(.buttonWhite) {
	color: var(--mainColor);
	font-size: 32px;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #fff;
	padding: var(--spacing2x) var(--spacing2x);
	text-align: center;
}

:local(.exitGame) {
	color: #444;
	cursor: pointer;
	font-size: 22px;
	padding: var(--spacing2x) var(--spacing2x);
	text-align: center;
}

:local(.resultTable) {
	width: 100%;
	font-size: 14px;
	border-collapse: collapse;
	margin-bottom: var(--spacing2x);
	border-top: 2px solid #eee;
	th {
		font-size: 12px;
		padding: 5px;
		border-bottom: 2px solid #eee;
	}
	td {
		font-size: 16px;
		border-bottom: 1px solid #eee;
		padding: 5px;
	}
}
