:local(.card) {
	--useColor: var(--mainColor);
	position: relative;
	overflow: hidden;
	--useColorTr: var(--mainColorTr);
	background-color: #fff;
	margin: var(--spacing2x);
	border-radius: 10px;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
	padding: var(--spacing);
}

:local(.question) {
	color: var(--useColor);
	font-size: 36px;
	font-weight: 700;
	text-align: center;
	margin-bottom: var(--spacing);
	padding: var(--spacing4x);
	border-bottom: 1px dotted var(--useColor);
}

:local(.options) {
	color: var(--useColor);
	font-size: 32px;
	padding: var(--spacing2x);
	&:local(.noSelectedOption) :local(.option):hover {
		background: var(--useColor);
		color: #fff;
	}
	:local(.option) {
		font-weight: 400;
		border: 1px solid var(--useColorTr);
		border-radius: 10px;
		text-align: center;
		background: var(--useColorTr);
		margin-bottom: var(--spacing);
		padding: var(--spacing2x);
		display: grid;
		grid-template-columns: auto 1fr;

		&.ok {
			background: var(--okColor);
			color: #fff;
		}
		&.error {
			background: var(--errorColor);
			color: #fff;
		}
		:local(.indicator) {
			width: 38px;
			height: 38px;
			border-radius: 30px;
			background: #fff;
			display: grid;
			place-content: center;
			img {
				width: 18px;
			}
		}
	}
}

:local(.card.status_error) {
	--useColor: var(--errorColor);
	--useColorTr: #cc330022;
}

:local(.card.status_ok) {
	--useColor: var(--okColor);
	--useColorTr: #00990022;
}

:local(.countdown) {
	height: 20px;
	background: #eee;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
