* {
	box-sizing: border-box;
}
:root {
	--mainColor: #7762c3;
	--mainColorBk: #d6d0ec;
	--mainColorTr: #7762c333;
	--errorColor: #c30;
	--okColor: #090;
	--spacing_half: 5px;
	--spacing: 10px;
	--spacing2x: 20px;
	--spacing4x: 40px;
}
html {
	user-select: none; /* Standard syntax */
	overscroll-behavior: none;
}
body {
	overscroll-behavior-y: contain;
	margin: 0 auto;
	padding: 0;
	width: 100%;
	height: 100dvh;
	font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.4rem;
}

.bounceLeft {
	animation: bounceLeft 1.5s infinite;
	animation-delay: 1.5s;
}

@keyframes bounceLeft {
	0%,
	100% {
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
	50% {
		-ms-transform: translateX(-30px);
		transform: translateX(-30px);
	}
}

.horizontalShaking {
	animation: horizontal-shaking 1s;
	animation-iteration-count: 1;
}

@keyframes horizontal-shaking {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(25px);
	}
	50% {
		transform: translateX(-25px);
	}
	75% {
		transform: translateX(25px);
	}
	100% {
		transform: translateX(0);
	}
}
